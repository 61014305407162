import { ASSET_REDIRECT_TARGET } from "projects/sso/src/app/data";

export const environment = {
    e2e: false,
    production: false,
    firebase: {
        apiKey: "AIzaSyB97KbJQbHTlUOqdPnN2Y6WLmqiSYt81jU",
        authDomain: "dcsptest-sso.service.deloitte.ai",
        projectId: "dcsptest",
    },
    region: "ToDOToBeDeleted",
    brain_base_url: "https://deloitte-test.apigee.net/dcsp-brain",
    authParams: ["oidc.azure"],
    breadcrumbs: {
        assetOwner: {
            aoh: "Asset Owner Hub",
            assetManagement: "Asset Management",
            principleAssetQuestions: "Principle asset questions",
            offerRequestDetail: "Offer request detail",
            assetPreview: "Preview",
        },
        store: {
            covid19Catalog: "Covid 19",
            daraCatalog: "Dara",
            smartForceCatalog: "SmartForce™",
            deloitteAiCatalog: "Deloitte.Ai",
            checkout: "Checkout",
            standingOrderDetail: "Standing order detail",
            storefront: "Storefront",
            orderHistory: "Order History",
        }
    },
    aoh_url: "https://dcsptest-aop.service.deloitte.ai",
    store_url: "https://dcsptest-store.service.deloitte.ai",
    api_base_url: "https://deloitte-test.apigee.net",
    hasStoreInStores: true,
    hasEnterpriseAssetSearch: true,
    asset_redirect_uri: {},
    onboarding: {
        displayVideo: true,
        video_url: "https://storage.googleapis.com/dcsp-catalog-assets-/data/products/Deloitte%20AI%20Video/Deloitte%20AI%20-%20Asset%20Owner%20Hub.mp4",
        development: {
            frontend_url: "https://storage.googleapis.com/dcsp-catalog-assets-public/data/products/Frontend_Development_Kit/Deloitte.AI%20Frontend%20Development%20Kit.pdf",
            backend_url: "https://bitbucket.de.deloitte.com/projects/AI/repos/dcsp-python-framework/browse",
            business_support_email: "mailto:GlobalDeloitteAi@deloitte.com?subject=Question%20regarding%20Deloitte%20AI%20Marketplace",
        }
    },
    barrierQuestionsAbort: {
        mailAddress: "psoldner@deloitte.de"
    },
    support: {
        url: "https://deloitteemea.service-now.com/spde?id=de_sc_cat_item&sys_id=1519b9051bace0101c9d96c9a54bcb28"
    }
};

/**
 * declare asset redirect uri's we use a enum for that so we could not
 * misspell it anymore and auto suggest will work
 */
environment.asset_redirect_uri[ASSET_REDIRECT_TARGET.SCIENTRIX] = "https://scientrix-test.service.deloitte.ai/login";
environment.asset_redirect_uri[ASSET_REDIRECT_TARGET.DPREDICT] = "https://dpredict-test.service.deloitte.ai/login";


// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import "zone.js/plugins/zone-error";  // Included with Angular CLI.
